<template>
  <div class="Operation">
    <div class="top-title">
      <div class="top-text">操作攻略</div>


        <div style="width:90%;margin:0 auto;">  <el-button style="float:right; background-color: #01847F; color:#fff" @click="fabu()" >发布攻略</el-button></div>
    </div>
     <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist.one" :key="i"> <span @click="tap(item)" :class="classactive == item.tapid?'active':''">{{item.tapname}}</span></li>
        </ul>
    </div>
  
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list"  v-loading="loading">
        <el-table-column prop="title" label="栏目名称"> </el-table-column>
        <el-table-column label="图片"> 
            <template slot-scope="scope">
                    <img :src="upLoadUrlto+scope.row.picurl" height="100"/>
            </template>
        </el-table-column>
       
        <el-table-column label="内容">
             <template slot-scope="scope">
                 
                    <span v-html="scope.row.content"></span>
            </template>
             </el-table-column>

        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"
              type="text">编辑</el-button>
            <el-button
              @click="handleClickr(scope.row)"
              class="table-form-btn"
              type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>




<!-- 新发布 -->
   <el-dialog :visible.sync="dialogFormVisible" width="600px">
     <div style="font-size:18px;"><span style="border-bottom:1px solid #999">{{classactive == 1?'会员发布':classactive == 2?'超级会员发布':classactive == 3?'经理发布':classactive == 4?'商家发布':''}}</span></div>
      <el-form  label-width="100px">
          <div class="activity-form">
           <div class="af-top">
                  <div class="afl-li">
                      <el-form-item label="栏目名称" >
                         <el-input v-model="listpar.contactNumber"></el-input>
                      </el-form-item>
                  </div>
            </div> 
            <div class="af-box">栏目内容</div>
            <div class="af-bottom">
                   <div class="top-box-image">
                    
                    <span>上传图片</span>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :action="upLoadUrl"
                      :data="headerMsg">
                      <img v-if="listpar.picurl" :src="upLoadUrlto+listpar.picurl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                   
                </div>
            </div>
             <div class="afl-li">
                      <el-form-item label="内容" >
                         <el-input type="textarea" rows="3" v-model="listpar.remark"></el-input>
                      </el-form-item>
                  </div>
          </div>
      </el-form>
          <div div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="tijiao()">提交</el-button>
            </div>
   </el-dialog>

<!-- 编辑 -->
   <el-dialog  :visible.sync="dialogFormVisibletwo" width="600px">
     <div style="font-size:18px;"><span style="border-bottom:1px solid #999">{{classactive == 1?'会员发布修改':classactive == 2?'超级会员发布修改':classactive == 3?'经理发布修改':classactive == 4?'商家发布修改':''}}</span></div>
      <el-form  label-width="100px">
          <div class="activity-form">
           <div class="af-top">
                  <div class="afl-li">
                      <el-form-item label="栏目名称" >
                         <el-input v-model="listpar.title"></el-input>
                      </el-form-item>
                  </div>
            </div> 
            <div class="af-box">栏目内容</div>
            <div class="af-bottom">
                   <div class="top-box-image">
                    
                    <span>上传图片</span>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :action="upLoadUrl"
                      :data="headerMsg">
                      <img v-if="listpar.picurl" :src="upLoadUrlto+listpar.picurl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                   
                </div>
            </div>
              <div class="afl-li">
                      <el-form-item label="内容" >
                         <el-input type="textarea" rows="3" v-model="listpar.content"></el-input>
                      </el-form-item>
               </div>
              <!-- <div class="afl-li" style=" text-align: center;">
                   <p>换行会自动转换为br/ 取消换行请删除br/ 一行换行</p>
               </div> -->
          </div>
      </el-form>
          <div div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="bianji()">提交</el-button>
            </div>
   </el-dialog>


  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.uploadingimg,
      headerMsg:{
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        uploadfile:'file',
      },
            // 上传图片
      imageUrl: "",
      // 图片路径地址
      upLoadUrlto:config.ImgUrl,

        classactive:1,
        taplist:{
         one:[
            {
               tapname:'平台',
               tapid:1,
            },
            {
               tapname:'商家',
               tapid:2,
            }, 
          ],},

      loading: this.$common.loading,
      list:[],

      dialogFormVisible :false,
      dialogFormVisibletwo :false,
      listpar:{},
  
    };
  },
  created() {
     this.getSong()
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
     let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'WizardEntity',
        handleMode:'fetchall',
        catalog:this.classactive,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.list = res.Result
       this.loading = false
      },

      fabu(){
        this.dialogFormVisible=true,
        this.listpar.picurl = ''
      },

      // 发布新操作攻略
       async tijiao(){
        let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'WizardEntity',
        handleMode:'upsert',
        bizData:{
          id:'',
          catalog:this.classactive -1,
          title:this.listpar.contactNumber,
          content:this.listpar.remark,
          // content:this.listpar.remark.replace(/\n/g,'<br/>'),
          picurl:this.listpar.picurl
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.getSong()
       
          this.$alert('发布成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.dialogFormVisible = false
          }
        });
        },

        // 编辑
        handleClick(row) {
          this.listpar = row
          this.dialogFormVisibletwo = true
        },
      // 编辑攻略
       async bianji(){
        let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'WizardEntity',
        handleMode:'upsert',
        bizData:{
          id:this.listpar.id,
          catalog:this.classactive -1,
          title:this.listpar.title,
          content:this.listpar.content,
          // content:this.listpar.content.replace(/\n/g,'<br/>'),
          picurl:this.listpar.picurl
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.$alert('编辑成功', '消息提示', {
            confirmButtonText: '确定',
          callback: action => {
            this.getSong()
            this.dialogFormVisibletwo = false
          }
        });
        },

    //删除弹窗
      handleClickr(row){
            this.listpar = row
          this.$confirm('是否确认删除', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.shanchu()
          }).catch(action => {
            console.log("取消");
          })
      },

      // 删除攻略
       async shanchu(){
        let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'WizardEntity',
        handleMode:'delete',
        bizId:this.listpar.id,
        bizData:{
        catalog:this.classactive -1,

        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        
          this.$alert('编辑成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.getSong()
            this.dialogFormVisibletwo = false
          }
        });
        },

      // 选项卡
      tap(e){
      this.currentPage = 1;
       this.classactive = e.tapid
       this.getSong()
      },
    // 图片上传
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // 图片路径拼接
      this.listpar.picurl = res.Result
    },

    beforeAvatarUpload() {
      console.log(" ");
    },


  },
  computed: {},
};
</script>


<style  scoped>
.activity-form .af-top{
  width: 60%;
  margin: 0 auto;
  

}
.activity-form .af-box{
  padding: 20px 0 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
}
.activity-form .af-bottom{
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}
/* 上传 */
.top-box-image {
  width: 60%;
  display: flex;
  justify-content: space-around;
  height: 90px;
  font-size: 14px;
  overflow: hidden;
}
.top-box-image span{
  line-height: 78px;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  border: 1px solid rgba(153, 153, 153, 0.637);
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  height: 78px;
  display: block;
}
.particulars-map{
  width: 100%;
  display: flex;
  justify-content: center;
}

.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>